import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ContractAttachmentListItemDTO,
  ContractViewItemDto,
  ContractDTOV1,
  SignerDTO,
} from "openapi";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { motion } from "framer-motion";
import { Box, Grid, Tooltip } from "@mui/material";
import { useSignatureProvider } from "pages/Contract/hooks/useSignature";
import Tasks from "pages/Tasks/Tasks";
import { BasicTable, CTAButton, NewModal } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import CancelSignatureModalContent from "../ContractData/ModalContents/ContractCancelSignature";
import StickyMenu from "./StickyMenu/StickyMenu";
import { isSendReminderAllowed, dateTimeFormatter } from "./helpers";
import { useUserInfo } from "hooks";
import { dateFormats } from "constants/utils";
import ContractChangeLog from "../ContractChangeLog/ContractChangeLog";
import { OverviewProvider } from "contexts/grid/grid";
import Documents from "../Documents/Documents";
import Related from "../Related/Related";
import {
  Wrapper,
  SignatureComponent,
  Header,
  Block,
  Message,
  Button,
} from "./styles";

export const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
};

const RemindButton = ({
  signer,
  contractId,
}: {
  signer: SignerDTO;
  contractId: string;
}) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const { sendReminder } = useSignatureProvider();
  const [isDisabled, setIsDisabled] = useState(false);
  const isSigned = signer.status === "signed";

  const handleRemindToSign = () => {
    if (!signer.id || !contractId) return;
    void sendReminder(contractId, signer.id);
    setIsDisabled(true);
  };

  const disabledTooltipTitle = isDisabled
    ? t("pages.contractDetails.signature.table.cells.reminder.tooltip.disabled")
    : "";
  const tooltipTitle = isSigned
    ? t("pages.contractDetails.signature.table.cells.reminder.tooltip.signed", {
        name: signer.name,
      })
    : disabledTooltipTitle;

  useEffect(() => {
    const isRequestAllowed = isSendReminderAllowed(signer, 2);

    if (isRequestAllowed && !isSigned) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [signer]);

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <span>
        <Button
          className={buttonClasses.previewHeaderButton}
          onClick={handleRemindToSign}
          disabled={isDisabled}
        >
          {t("common.buttons.remind")}
        </Button>
      </span>
    </Tooltip>
  );
};

const usePreviousState = (value: number) => {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export type RightSideProps = {
  fetchData: () => Promise<void>;
  fetchPdf: () => Promise<void>;
  contract: ContractDTOV1;
  contractGroup: ContractAttachmentListItemDTO[];
};

export const RightSide = ({
  fetchData,
  fetchPdf,
  contract,
  contractGroup,
}: RightSideProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState({ id: 1, key: "documents" });
  const { mode } = useParams();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("task") ?? undefined;
  const [showCancelSignatureModal, setShowCancelSignatureModal] =
    useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const refetchData = () => {
    void fetchData();
    void fetchPdf();
  };

  const handleSelectedTab = (id: number, key: string) => {
    setSelectedTab({ id, key });
  };

  const prevSelectedTab = usePreviousState(selectedTab?.id);

  useEffect(() => {
    if (prevSelectedTab === 5) {
      setSelectedTab({ id: 5, key: "log" });
      return;
    }
    if (prevSelectedTab === 4) {
      setSelectedTab({ id: 4, key: "related" });
      return;
    }
    if (taskId || mode === "tasks" || prevSelectedTab === 2) {
      setSelectedTab({ id: 2, key: "tasks" });
      return;
    }
    if (
      contract?.signature?.status === "DRAFT" ||
      contract?.signature?.status === "SIGNING" ||
      contract?.status === "SIGNING"
    ) {
      setSelectedTab({ id: 3, key: "esignature" });
      return;
    }
    setSelectedTab({ id: 1, key: "documents" });
  }, [contract]);

  const isCancelSignatureDisabled = contract?.status !== "SIGNING";

  const tableHeaders = [
    ...(contract?.signature?.provider === "signaturit"
      ? []
      : [
          {
            key: "action",
            name: t("pages.contractDetails.signature.table.headers.action"),
          },
        ]),
    {
      key: "email",
      name: t("pages.contractDetails.signature.table.headers.email"),
    },
    {
      key: "status",
      name: t("pages.contractDetails.signature.table.headers.status"),
    },
    {
      key: "requestedAt",
      name: t("pages.contractDetails.signature.table.headers.requestedAt"),
    },
    {
      key: "signedAt",
      name: t("pages.contractDetails.signature.table.headers.signedAt"),
    },
    {
      key: "remindedAt",
      name: t("pages.contractDetails.signature.table.headers.remindedAt"),
    },
    {
      key: "lastViewedAt",
      name: t("pages.contractDetails.signature.table.headers.lastViewedAt"),
    },
  ];

  const signersData = (contract?.signature?.signers ?? []).map((signer) => {
    return {
      action: <RemindButton signer={signer} contractId={contract.id} />,
      ...signer,
      status: t(
        `pages.contractDetails.signature.table.cells.status.${signer?.status}`
      ),
      // @Note: Improve UI and add x day ago, x week ago etc. so it is more user friendly :)
      requestedAt: dateTimeFormatter(signer?.requestedAt ?? "", userDateFormat),
      signedAt: dateTimeFormatter(signer?.signedAt ?? "", userDateFormat),
      remindedAt: dateTimeFormatter(signer?.remindedAt ?? "", userDateFormat),
      lastViewedAt: dateTimeFormatter(
        signer?.lastViewedAt ?? "",
        userDateFormat
      ),
    };
  });

  const closeCancelSignatureModal = () => {
    setShowCancelSignatureModal(false);
  };
  return (
    <Wrapper data-testid="contract-right-side">
      {selectedTab.key === "documents" && (
        <>
          <Documents contractTeamId={contract?.teamId} />
        </>
      )}
      {selectedTab.key === "tasks" && (
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "sticky",
              top: 80,
            }}
          >
            <motion.div
              {...motionProps}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "80vh",
              }}
            >
              <OverviewProvider
                key={ContractViewItemDto.type.TASK}
                type={ContractViewItemDto.type.TASK}
              >
                <Tasks
                  contractId={contract?.id}
                  viewColumns={["title", "status", "dueDate"]}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    boxShadow: "none",
                  }}
                />
              </OverviewProvider>
            </motion.div>
          </Box>
        </Grid>
      )}
      {selectedTab.key === "esignature" && !!contract.signature ? (
        <CardWrapper
          height="80vh"
          style={{
            display: "flex",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {
            <SignatureComponent data-testid="signature-component">
              {!contract?.signature ? (
                <Header>
                  {t("pages.contractDetails.signature.message.inactive")}
                </Header>
              ) : (
                <>
                  <Header>
                    <Block>
                      {isCancelling ? (
                        <div>
                          {t(
                            "pages.contractDetails.signature.message.cancelling"
                          )}
                        </div>
                      ) : (
                        <Message>
                          <span>
                            {t(
                              `pages.contractDetails.signature.message.currentStatus`
                            )}
                          </span>
                          <span>
                            {t(
                              `pages.contractDetails.signature.status.${
                                contract?.signature?.status ?? ""
                              }`
                            )}
                          </span>
                        </Message>
                      )}
                    </Block>

                    <Button
                      sx={{ textTransform: "none" }}
                      disabled={isCancelSignatureDisabled || isCancelling}
                      onClick={() => setShowCancelSignatureModal(true)}
                    >
                      {t("common.buttons.cancel")}
                    </Button>
                  </Header>

                  <OverlayScrollbarsComponent>
                    <BasicTable headers={tableHeaders} data={signersData} />
                  </OverlayScrollbarsComponent>
                </>
              )}
            </SignatureComponent>
          }
        </CardWrapper>
      ) : null}
      {selectedTab.key === "related" && (
        <CardWrapper
          height="80vh"
          style={{
            display: "flex",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
            }}
          >
            <Related
              contractGroup={contractGroup}
              contractData={contract}
              fetchPdf={fetchPdf}
              fetchData={fetchData}
            />
          </Box>
        </CardWrapper>
      )}
      {selectedTab.key === "log" && (
        <CardWrapper
          height="80vh"
          style={{
            display: "flex",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            paddingRight: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
            }}
          >
            <ContractChangeLog contract={contract} />
          </Box>
        </CardWrapper>
      )}
      <StickyMenu
        currentSelectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        provider={contract?.signature?.provider}
      />

      <NewModal
        open={showCancelSignatureModal}
        handleClose={closeCancelSignatureModal}
        title={t("pages.contractDetails.modals.cancelSignature.title")}
        body={
          <CancelSignatureModalContent
            contractId={contract?.id}
            handleClose={closeCancelSignatureModal}
            refetchData={refetchData}
            provider={contract?.signature?.provider}
            setIsCancelling={setIsCancelling}
          />
        }
        footer={
          <>
            <CTAButton
              label="cancel"
              name={t("common.buttons.abort")}
              variant="secondary"
              onClick={closeCancelSignatureModal}
            />
            <CTAButton
              label="verifyAll"
              name={t("common.buttons.submit")}
              variant="primary"
              form="cancelSignatureForm"
              type="submit"
            />
          </>
        }
      />
    </Wrapper>
  );
};

export default RightSide;

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  $nodesOfType,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_NORMAL,
  CONTROLLED_TEXT_INSERTION_COMMAND,
  createCommand,
  LexicalCommand,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useEffect } from "react";
import { mergeRegister } from "@lexical/utils";
import { TemplateComponentProps } from "../components/PlaceholderComponent/PlaceholderComponent";
import {
  $createPlaceholderTextNode,
  $isPlaceholderNode,
  PlaceholderTextNode,
} from "../nodes/PlaceholderTextNode";

type FillPlaceholderPayload = {
  origin: string;
  slug: string;
  fieldKey: string;
  value: string | null;
};

export const FILL_PLACEHOLDER_COMMAND: LexicalCommand<FillPlaceholderPayload> =
  createCommand("FILL_PLACEHOLDER_COMMAND");

type TemplatePluginProps = {
  getLocalizedLabelParts?: (
    slug: string,
    fieldKey: string
  ) => {
    slug: string | null;
    fieldKey: string | null;
  };
  onFillPlaceholder?: (payload: FillPlaceholderPayload) => void;
};

export default function TemplatePlugin(props: TemplatePluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(PlaceholderTextNode, (node) => {
        if (props.getLocalizedLabelParts && !node.wasModifiedExternally()) {
          const localizedLabelParts = props.getLocalizedLabelParts(
            node.getSlug(),
            node.getFieldKey()
          );
          node.setTextContent(
            PlaceholderTextNode.getDefaultText(
              localizedLabelParts.slug || node.getSlug(),
              localizedLabelParts.fieldKey || node.getFieldKey()
            )
          );
        }
      }),
      editor.registerCommand(
        FILL_PLACEHOLDER_COMMAND,
        (payload) => {
          const { slug, fieldKey, value } = payload;
          const placeholders = $nodesOfType(PlaceholderTextNode);

          const targetPlaceholder = placeholders.find(
            (placeholder) =>
              placeholder.getSlug() === slug &&
              placeholder.getFieldKey() === fieldKey
          );
          if (targetPlaceholder) {
            targetPlaceholder.setTextContent(value ?? "", "external");
            props.onFillPlaceholder?.(payload);
            return true;
          } else {
            return false;
          }
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }

          const firstPoint = selection.isBackward()
            ? selection.focus
            : selection.anchor;
          const lastPoint = selection.isBackward()
            ? selection.anchor
            : selection.focus;

          const firstNode = firstPoint.getNode();
          const lastNode = lastPoint.getNode();
          let updated = false;

          if (
            $isPlaceholderNode(firstNode) &&
            firstPoint.offset > 0 &&
            firstPoint.offset < firstNode.getTextContentSize()
          ) {
            firstPoint.offset = 0;
            updated = true;
          }

          if (
            $isPlaceholderNode(lastNode) &&
            lastPoint.offset > 0 &&
            lastPoint.offset < lastNode.getTextContentSize()
          ) {
            lastPoint.offset = lastNode.getTextContentSize();
            updated = true;
          }

          if (updated) {
            $setSelection(selection);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        CONTROLLED_TEXT_INSERTION_COMMAND,
        (e) => {
          const selection = $getSelection();

          if (typeof e === "string" || e.inputType !== "insertFromDrop") {
            return false;
          }

          const data = JSON.parse(
            e.dataTransfer?.getData("text/plain") || "{}"
          ) as TemplateComponentProps;

          if ($isRangeSelection(selection)) {
            const node = $getNodeByKey(selection.anchor.key);
            if (!node) {
              return false;
            }

            const placeholderNode = $createPlaceholderTextNode(
              data.slug,
              data.fieldKey
            );

            if ($isRootOrShadowRoot(node)) {
              $insertNodes([placeholderNode]);
              return true;
            }

            if ($isParagraphNode(node)) {
              node.append(placeholderNode);
              placeholderNode.selectEnd();
              return true;
            }

            if ($isPlaceholderNode(node)) {
              if (selection.focus.offset < node.getTextContentSize() / 2) {
                node.insertBefore(placeholderNode);
              } else {
                node.insertAfter(placeholderNode);
              }
              placeholderNode.selectEnd();
              return true;
            }

            if (!$isTextNode(node) || !node.isSimpleText()) {
              return false;
            }

            if (selection.anchor.offset === 0) {
              node.insertBefore(placeholderNode);
            } else {
              const [targetNode] = node.splitText(selection.anchor.offset);
              targetNode.insertAfter(placeholderNode);
            }
            placeholderNode.selectEnd();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor]);

  return null;
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignCategoryToTeamDTO } from "../models/AssignCategoryToTeamDTO";
import type { AssignTeamToCategoryDTO } from "../models/AssignTeamToCategoryDTO";
import type { BackupLogDTO } from "../models/BackupLogDTO";
import type { CategoryCreateDTO } from "../models/CategoryCreateDTO";
import type { CategoryDTO } from "../models/CategoryDTO";
import type { CategoryTeamDTO } from "../models/CategoryTeamDTO";
import type { CategoryUpdateDTO } from "../models/CategoryUpdateDTO";
import type { ContractChangeLogDTO } from "../models/ContractChangeLogDTO";
import type { ContractFieldCreateDTOV1 } from "../models/ContractFieldCreateDTOV1";
import type { ContractFieldDTOV1 } from "../models/ContractFieldDTOV1";
import type { ContractFieldUpdateDTOV1 } from "../models/ContractFieldUpdateDTOV1";
import type { ContractTransferRequestDTO } from "../models/ContractTransferRequestDTO";
import type { ContractTransferResultDTO } from "../models/ContractTransferResultDTO";
import type { CreateWebhookDto } from "../models/CreateWebhookDto";
import type { InvitationCreateDTO } from "../models/InvitationCreateDTO";
import type { InvitationDTO } from "../models/InvitationDTO";
import type { OrganizationDTO } from "../models/OrganizationDTO";
import type { OrganizationUpdateDTO } from "../models/OrganizationUpdateDTO";
import type { PermissionChangeLogDTO } from "../models/PermissionChangeLogDTO";
import type { TaskAutomationCreateDTO } from "../models/TaskAutomationCreateDTO";
import type { TaskAutomationDTO } from "../models/TaskAutomationDTO";
import type { TaskAutomationUpdateDTO } from "../models/TaskAutomationUpdateDTO";
import type { TeamDTO } from "../models/TeamDTO";
import type { UpdateWebhookDTO } from "../models/UpdateWebhookDTO";
import type { VerifyAnalysisDTO } from "../models/VerifyAnalysisDTO";
import type { WebhookDTO } from "../models/WebhookDTO";
import type { WebhookLogDTO } from "../models/WebhookLogDTO";
import type { WebhookLogListDTO } from "../models/WebhookLogListDTO";
import type { WebhookTestResultDTO } from "../models/WebhookTestResultDTO";
import type { WebhookUrlDTO } from "../models/WebhookUrlDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrganizationNewService {
  /**
   * Get organizations the users can access
   * @returns OrganizationDTO
   * @throws ApiError
   */
  public static getOrganizationsOfUser(): CancelablePromise<
    Array<OrganizationDTO>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations",
    });
  }

  /**
   * Get an organization by id
   * @param organizationId
   * @returns OrganizationDTO
   * @throws ApiError
   */
  public static getOrganization(
    organizationId: string
  ): CancelablePromise<OrganizationDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Update an organization
   * @param organizationId
   * @param requestBody
   * @returns OrganizationDTO
   * @throws ApiError
   */
  public static updateOrganization(
    organizationId: string,
    requestBody: OrganizationUpdateDTO
  ): CancelablePromise<OrganizationDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get backup logs of an organization
   * @param organizationId
   * @returns BackupLogDTO
   * @throws ApiError
   */
  public static getBackupLogs(
    organizationId: string
  ): CancelablePromise<Array<BackupLogDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/backup-logs",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get organization permission's change history
   * @param organizationId
   * @returns PermissionChangeLogDTO
   * @throws ApiError
   */
  public static getOrganizationPermissionHistory(
    organizationId: string
  ): CancelablePromise<Array<PermissionChangeLogDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/history",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Verify all datapoints of a contract
   * @param organizationId
   * @param teamId
   * @param contractId
   * @returns any
   * @throws ApiError
   */
  public static verifyContract(
    organizationId: string,
    teamId: string,
    contractId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/verify-analysis",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
      },
    });
  }

  /**
   * Selectively verify datapoint fields of a contract
   * @param organizationId
   * @param teamId
   * @param contractId
   * @param datapointId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static verifyDatapoint(
    organizationId: string,
    teamId: string,
    contractId: string,
    datapointId: string,
    requestBody: VerifyAnalysisDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/datapoints/{datapointId}/verify-analysis",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
        datapointId: datapointId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get contract change log history
   * @param contractId
   * @param organizationId
   * @returns ContractChangeLogDTO
   * @throws ApiError
   */
  public static getContractHistory(
    contractId: string,
    organizationId: string
  ): CancelablePromise<Array<ContractChangeLogDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/history",
      path: {
        contractId: contractId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get all teams of an organization
   * @param organizationId
   * @returns TeamDTO
   * @throws ApiError
   */
  public static getTeams(
    organizationId: string
  ): CancelablePromise<Array<TeamDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get a team by id
   * @param organizationId
   * @param teamId
   * @returns TeamDTO
   * @throws ApiError
   */
  public static getTeam(
    organizationId: string,
    teamId: string
  ): CancelablePromise<TeamDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * Assign a category to a team
   * @param organizationId
   * @param teamId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static assignTeamToCategory(
    organizationId: string,
    teamId: string,
    requestBody: AssignCategoryToTeamDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{teamId}/categories",
      path: {
        organizationId: organizationId,
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Remove a category from a team
   * @param organizationId
   * @param teamId
   * @param categoryId
   * @returns any
   * @throws ApiError
   */
  public static removeTeamFromCategory(
    organizationId: string,
    teamId: string,
    categoryId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/teams/{teamId}/categories/{categoryId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Transfer contracts from one team to another in batch
   * @param organizationId
   * @param sourceTeamId
   * @param targetTeamId
   * @param requestBody
   * @returns ContractTransferResultDTO
   * @throws ApiError
   */
  public static transferContracts(
    organizationId: string,
    sourceTeamId: string,
    targetTeamId: string,
    requestBody: ContractTransferRequestDTO
  ): CancelablePromise<Array<ContractTransferResultDTO>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{sourceTeamId}/transfer-contracts/to/{targetTeamId}",
      path: {
        organizationId: organizationId,
        sourceTeamId: sourceTeamId,
        targetTeamId: targetTeamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get all categories of an organization
   * @param organizationId
   * @returns CategoryDTO
   * @throws ApiError
   */
  public static getCategories(
    organizationId: string
  ): CancelablePromise<Array<CategoryDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/categories",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Create a new category
   * @param organizationId
   * @param requestBody
   * @returns CategoryDTO
   * @throws ApiError
   */
  public static createCategory(
    organizationId: string,
    requestBody: CategoryCreateDTO
  ): CancelablePromise<CategoryDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/categories",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get a category by id
   * @param organizationId
   * @param categoryId
   * @returns CategoryDTO
   * @throws ApiError
   */
  public static getCategory(
    organizationId: string,
    categoryId: string
  ): CancelablePromise<CategoryDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/categories/{categoryId}",
      path: {
        organizationId: organizationId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Update a category
   * @param organizationId
   * @param categoryId
   * @param requestBody
   * @returns CategoryDTO
   * @throws ApiError
   */
  public static updateCategory(
    organizationId: string,
    categoryId: string,
    requestBody: CategoryUpdateDTO
  ): CancelablePromise<CategoryDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/categories/{categoryId}",
      path: {
        organizationId: organizationId,
        categoryId: categoryId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a category
   * @param organizationId
   * @param categoryId
   * @returns CategoryDTO
   * @throws ApiError
   */
  public static deleteCategory(
    organizationId: string,
    categoryId: string
  ): CancelablePromise<CategoryDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/categories/{categoryId}",
      path: {
        organizationId: organizationId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Get all teams connected to a category
   * @param organizationId
   * @param categoryId
   * @returns CategoryTeamDTO
   * @throws ApiError
   */
  public static getConnectedTeamsOfCategory(
    organizationId: string,
    categoryId: string
  ): CancelablePromise<Array<CategoryTeamDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/categories/{categoryId}/teams",
      path: {
        organizationId: organizationId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Connect a team to a category
   * @param organizationId
   * @param categoryId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static assignTeamToCategory1(
    organizationId: string,
    categoryId: string,
    requestBody: AssignTeamToCategoryDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/categories/{categoryId}/teams",
      path: {
        organizationId: organizationId,
        categoryId: categoryId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Disconnect a team from a category
   * @param organizationId
   * @param teamId
   * @param categoryId
   * @returns any
   * @throws ApiError
   */
  public static removeTeamFromCategory1(
    organizationId: string,
    teamId: string,
    categoryId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/categories/{categoryId}/teams/{teamId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Get the list of contract fields.
   * @param organizationId
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static getFieldsOfOrganization(
    organizationId: string
  ): CancelablePromise<Array<ContractFieldDTOV1>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/contracts/definitions",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Create a contract field definition.
   * @param organizationId
   * @param requestBody
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static createContractField(
    organizationId: string,
    requestBody: ContractFieldCreateDTOV1
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/contracts/definitions",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Update a contract field.
   * @param organizationId
   * @param fieldId
   * @param requestBody
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static updateContractField(
    organizationId: string,
    fieldId: string,
    requestBody: ContractFieldUpdateDTOV1
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/contracts/definitions/{fieldId}",
      path: {
        organizationId: organizationId,
        fieldId: fieldId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract field.
   * @param organizationId
   * @param fieldId
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static deleteContractField(
    organizationId: string,
    fieldId: string
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/contracts/definitions/{fieldId}",
      path: {
        organizationId: organizationId,
        fieldId: fieldId,
      },
    });
  }

  /**
   * Create new webhook
   * @param organizationId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static createWebhook(
    organizationId: string,
    requestBody: CreateWebhookDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/webhooks",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get all webhooks for organization
   * @param organizationId
   * @returns WebhookDTO
   * @throws ApiError
   */
  public static getAllWebhooks(
    organizationId: string
  ): CancelablePromise<Array<WebhookDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/webhooks",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get webhook with id
   * @param webhookId
   * @param organizationId
   * @returns WebhookDTO
   * @throws ApiError
   */
  public static getWebhook(
    webhookId: string,
    organizationId: string
  ): CancelablePromise<WebhookDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/webhooks/{webhookId}",
      path: {
        webhookId: webhookId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Update webhook with id
   * @param webhookId
   * @param organizationId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateWebhook(
    webhookId: string,
    organizationId: string,
    requestBody: UpdateWebhookDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/webhooks/{webhookId}",
      path: {
        webhookId: webhookId,
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete webhook with id
   * @param webhookId
   * @param organizationId
   * @returns any
   * @throws ApiError
   */
  public static deleteWebhook(
    webhookId: string,
    organizationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/webhooks/{webhookId}",
      path: {
        webhookId: webhookId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get logs of webhook
   * @param webhookId
   * @param organizationId
   * @returns WebhookLogListDTO
   * @throws ApiError
   */
  public static getWebhookLogs(
    webhookId: string,
    organizationId: string
  ): CancelablePromise<Array<WebhookLogListDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/webhooks/{webhookId}/logs",
      path: {
        webhookId: webhookId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get log of webhook per ID
   * @param webhookId
   * @param logId
   * @param organizationId
   * @returns WebhookLogDTO
   * @throws ApiError
   */
  public static getWebhookLog(
    webhookId: string,
    logId: string,
    organizationId: string
  ): CancelablePromise<WebhookLogDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/webhooks/{webhookId}/logs/{logId}",
      path: {
        webhookId: webhookId,
        logId: logId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Test Webhook
   * @param organizationId
   * @param requestBody
   * @returns WebhookTestResultDTO
   * @throws ApiError
   */
  public static testWebhook(
    organizationId: string,
    requestBody: WebhookUrlDTO
  ): CancelablePromise<WebhookTestResultDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/webhooks/test",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Create a task automation rule
   * @param organizationId
   * @param requestBody
   * @returns TaskAutomationDTO
   * @throws ApiError
   */
  public static createTaskAutomation(
    organizationId: string,
    requestBody: TaskAutomationCreateDTO
  ): CancelablePromise<TaskAutomationDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/task-automations",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get a list of task automations
   * @param organizationId
   * @returns TaskAutomationDTO
   * @throws ApiError
   */
  public static getTaskAutomations(
    organizationId: string
  ): CancelablePromise<Array<TaskAutomationDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/task-automations",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get a task automation by id
   * @param organizationId
   * @param taskAutomationId
   * @returns TaskAutomationDTO
   * @throws ApiError
   */
  public static getTaskAutomationById(
    organizationId: string,
    taskAutomationId: string
  ): CancelablePromise<TaskAutomationDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/task-automations/{taskAutomationId}",
      path: {
        organizationId: organizationId,
        taskAutomationId: taskAutomationId,
      },
    });
  }

  /**
   * Delete a task automation by id
   * @param organizationId
   * @param taskAutomationId
   * @returns TaskAutomationDTO
   * @throws ApiError
   */
  public static deleteTaskAutomation(
    organizationId: string,
    taskAutomationId: string
  ): CancelablePromise<TaskAutomationDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/task-automations/{taskAutomationId}",
      path: {
        organizationId: organizationId,
        taskAutomationId: taskAutomationId,
      },
    });
  }

  /**
   * Update a task automation
   * @param organizationId
   * @param taskAutomationId
   * @param requestBody
   * @returns TaskAutomationDTO
   * @throws ApiError
   */
  public static updateTaskAutomation(
    organizationId: string,
    taskAutomationId: string,
    requestBody: TaskAutomationUpdateDTO
  ): CancelablePromise<TaskAutomationDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/task-automations/{taskAutomationId}",
      path: {
        organizationId: organizationId,
        taskAutomationId: taskAutomationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get invitations of an organization
   * @param organizationId
   * @returns InvitationDTO
   * @throws ApiError
   */
  public static getInvitations(
    organizationId: string
  ): CancelablePromise<Array<InvitationDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/invitations",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Invite user to organization
   * @param organizationId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static inviteMember(
    organizationId: string,
    requestBody: InvitationCreateDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/invitations",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete invitation of an organization
   * @param organizationId
   * @param invitationId
   * @returns InvitationDTO
   * @throws ApiError
   */
  public static deleteInvitation(
    organizationId: string,
    invitationId: string
  ): CancelablePromise<InvitationDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/invitations/{invitationId}",
      path: {
        organizationId: organizationId,
        invitationId: invitationId,
      },
    });
  }

  /**
   * Add a support user to organization
   * @param organizationId
   * @returns any
   * @throws ApiError
   */
  public static addSupportMember(
    organizationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/support",
      path: {
        organizationId: organizationId,
      },
    });
  }
}

import React from "react";
import Dropdown from "./Dropdown/Dropdown";
import { enqueueSnackbar } from "notistack";
import { CTAButton } from "components";
import ArrowLeftIcon from "assets/svg/arrow-left.svg?react";
import { Container, TextContainer } from "./styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { useTeam } from "../../../../../../contexts/team/hooks";
import { Features } from "../../../../../../constants";
import { AcceptedFileType } from "../../../../../../shared/enums/document.enum";

export type DocumentHeaderProps = {
  contractFile?: Blob | undefined | null;
  headerTitle?: string;
  onBackClick?: () => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
};

const DocumentHeader = ({
  contractFile,
  headerTitle,
  onBackClick,
  onDeleteClick,
  onDownloadClick,
}: DocumentHeaderProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useTeam();

  const printPDF = () => {
    const file = new Blob([contractFile as Blob], { type: contractFile?.type });
    const fileUrl = `${URL.createObjectURL(file)}`;
    const newWindow = window.open(fileUrl, "_blank");
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.focus();
        newWindow.print();
      };
    } else {
      enqueueSnackbar(t("pages.contractDetails.documents.printDocumentError"), {
        variant: "error",
      });
    }
  };

  return (
    <Container>
      {hasFeature(Features.MULTIPLE_DOCUMENT) && onBackClick && (
        <Tooltip title={t("pages.contractDetails.buttons.backToList")}>
          <CTAButton
            variant="tertiary"
            icon={<ArrowLeftIcon />}
            onClick={onBackClick}
            iconSize="medium"
            size="tiny"
            color="blue"
          />
        </Tooltip>
      )}
      <TextContainer>
        <span>{headerTitle || ""}</span>
      </TextContainer>
      <Dropdown
        onDeleteClick={onDeleteClick}
        onPrintClick={printPDF}
        onDownloadClick={onDownloadClick}
        isPdf={contractFile?.type === AcceptedFileType.PDF}
      />
    </Container>
  );
};

export default DocumentHeader;

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { CloseButton, Text, Wrapper } from "./styles";
import { getCookie, setCookie } from "./utils";
import Cross from "assets/svg/cross-icon.svg?react";
import { FormCheckbox } from "components/FormItems";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type PopoverProps = {
  targetRef: React.RefObject<HTMLElement>;
  text: string;
};

export type PositionType = {
  top?: number;
  left?: number;
};

export const trianglePosition = {
  top: 6,
  left: 10,
};

const Popover = ({ targetRef, text }: PopoverProps) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<PositionType>({ top: 0, left: 0 });
  const [positionCalculated, setPositionCalculated] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const { control, getValues } = useForm();

  const calculatePosition = () => {
    if (targetRef.current && popoverRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();

      setPosition({
        top: rect.top + window.scrollY - trianglePosition.top,
        left:
          rect.left +
          window.scrollX -
          popoverRect.width -
          trianglePosition.left,
      });
      setPositionCalculated(true);
    }
  };

  useEffect(() => {
    const isClosed = getCookie("RelatedPopoverClosed");
    if (!isClosed || isClosed !== "true") {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (show) {
      calculatePosition();
      window.addEventListener("resize", calculatePosition);
    }
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [show, targetRef, popoverRef]);

  const handleClose = () => {
    setShow(false);
  };

  const handleDontShowAgain = () => {
    const checked = getValues().dontShowAgain as boolean;
    setCookie("RelatedPopoverClosed", checked, 365);
  };

  if (!show || !targetRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <Wrapper
      aria-label="popover"
      ref={popoverRef}
      style={{
        top: `${position?.top ? position?.top : 0}px`,
        left: `${position?.left ? position?.left : 0}px`,
        opacity: positionCalculated ? "1" : "0",
      }}
    >
      <Text>{text}</Text>
      <CloseButton
        label="relatedDocumentsPopoverCloseButton"
        variant="tertiary"
        onClick={handleClose}
        icon={<Cross />}
        iconSize="tiny"
        color="white"
      />
      <FormCheckbox
        control={control}
        name={"dontShowAgain"}
        onChangeCallback={handleDontShowAgain}
        sx={{ color: "white", padding: 0 }}
        label={
          <span style={{ color: "white" }}>{t("common.labels.gotIt")}</span>
        }
      />
    </Wrapper>,
    document.body
  );
};

export default Popover;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { CTAButton, NewModal } from "components";
import ContractUpload from "components/ContractUpload/ContractUpload";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import { ContractNameDto, ContractDTOV1 } from "openapi";
import { theme } from "theme";
import Plus from "../../../../../assets/svg/plus.svg?react";
import { Tooltip } from "@mui/material";

type Props = {
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  fetchPdf: (loadFromStash?: boolean | undefined) => Promise<void>;
  contractData?: ContractDTOV1;
  contractGroup: ContractNameDto[];
};

const AddRelated = ({ contractData, contractGroup, fetchData }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showAddRelatedModal, setShowAddRelatedModal] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleOnOpen = () => setShowAddRelatedModal(true);

  const handleClose = () => {
    setShowAddRelatedModal(false);
    void fetchData();
  };

  return (
    <>
      <Tooltip title={t("pages.contractDetails.buttons.addAttachments")}>
        <CTAButton
          variant="tertiary"
          icon={<Plus />}
          size="tiny"
          onClick={handleOnOpen}
          data-cy="addAttachment"
        />
      </Tooltip>

      <NewModal
        open={showAddRelatedModal}
        handleClose={handleClose}
        icon={<AttachFileIcon fontSize="small" sx={{ marginRight: ".4rem" }} />}
        title={t("pages.contractDetails.modals.addAttachments.title")}
        headerProps={{
          style: {
            transition: `background-color 0.25s ease-in-out`,
            backgroundColor: isDragActive ? `${theme.color.blue[200]}80` : "",
          },
        }}
        body={
          <ContractUpload
            contractId={id}
            contractData={contractData}
            contractGroup={contractGroup}
            handleClose={handleClose}
            uploadType={UploadTypeEnum.ATTACHMENT_CONTRACT}
            setIsDragActive={setIsDragActive}
          />
        }
        bodyProps={{
          noPadding: true,
        }}
        fullWidth
        disableBackdropClick
      />
    </>
  );
};

export default AddRelated;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentService } from "openapi/services/DocumentService";
import { DocumentUpdateDTO } from "../../openapi";

export const useAddDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      organizationId,
      teamId,
      contractId,
      file,
    }: {
      organizationId: string;
      teamId: string;
      contractId: string;
      file: Blob;
    }) => {
      try {
        return DocumentService.addDocument(organizationId, teamId, contractId, {
          file,
        });
      } catch (error) {
        console.error("Unknown Error:", error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(["documents"]);
      },
    }
  );
};

/**
 * Fetch documents of a contract using React Query
 */
export const useFetchDocumentsQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined
) => {
  return useQuery(
    ["documents", organizationId, teamId, contractId],
    async () => {
      if (contractId && teamId) {
        return DocumentService.getAllDocuments(
          organizationId,
          teamId,
          contractId
        );
      }
    },
    {
      enabled: !!contractId && !!teamId,
    }
  );
};

export const useDownloadDocumentQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.downloadDocument(
          organizationId,
          teamId,
          contractId,
          documentId
        );
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};

export const useFetchDocumentQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId, "metadata"],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.getDocument(
          organizationId,
          teamId,
          contractId,
          documentId
        );
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      organizationId,
      teamId,
      contractId,
      documentId,
    }: {
      organizationId: string;
      teamId: string;
      contractId: string | undefined;
      documentId: string | undefined;
    }) => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.deleteDocument(
          organizationId,
          teamId,
          documentId,
          contractId
        );
      }
    },
    {
      onSuccess: (_, { organizationId, teamId, contractId }) => {
        void queryClient.invalidateQueries([
          "documents",
          organizationId,
          teamId,
          contractId,
        ]);
      },
    }
  );
};

export const useUpdateDocumentDataMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      organizationId,
      teamId,
      contractId,
      data,
      documentId,
    }: {
      organizationId: string;
      teamId: string;
      contractId: string;
      documentId: string;
      data: DocumentUpdateDTO;
    }) => {
      return DocumentService.updateDocumentData(
        organizationId,
        teamId,
        documentId,
        contractId,
        data
      );
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(["documents"]);
      },
    }
  );
};

export const useUpdateDocumentFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      organizationId,
      teamId,
      contractId,
      file,
      documentId,
    }: {
      organizationId: string;
      teamId: string;
      contractId: string;
      documentId: string;
      file: Blob;
    }) => {
      return DocumentService.updateDocumentFile(
        organizationId,
        teamId,
        documentId,
        contractId,
        { file }
      );
    },
    {
      onSuccess: (_, { organizationId, teamId, contractId, documentId }) => {
        void queryClient.invalidateQueries([
          "document",
          organizationId,
          teamId,
          contractId,
          documentId,
          "metadata",
        ]);

        void queryClient.invalidateQueries([
          "documents",
          organizationId,
          teamId,
          contractId,
          documentId,
        ]);
      },
    }
  );
};

export const useDocumentOcrResultQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId, "ocr"],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.getDocumentOcrResult(
          organizationId,
          teamId,
          documentId,
          contractId
        );
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};

export const basicColors = [
  "#ff0000",
  "#e67c73",
  "#d50000",
  "#c62828",
  "#b71c1c",
  "#880e4f",
  "#ad1457",
  "#f48fb1",
  "#ff4081",
  "#d81b60",
  "#ff80ab",
  "#ff7043",
  "#f4511e",
  "#ff5722",
  "#bf360c",
  "#e65100",
  "#ff6f00",
  "#ffab00",
  "#fbbc04",
  "#ffeb3b",
  "#fdd835",
  "#ffff00",
  "#ffca28",
  "#ffb74d",
  "#ffcc80",
  "#ffe082",
  "#ffff8d",
  "#ccff90",
  "#8bc34a",
  "#558b2f",
  "#33691e",
  "#00c853",
  "#00e676",
  "#64dd17",
  "#76ff03",
  "#aeea00",
  "#009688",
  "#00bfa5",
  "#00acc1",
  "#00bcd4",
  "#26c6da",
  "#18ffff",
  "#80deea",
  "#4dd0e1",
  "#00838f",
  "#0277bd",
  "#536dfe",
  "#3d5afe",
  "#1a237e",
  "#304ffe",
  "#0d47a1",
  "#1976d2",
  "#1e88e5",
  "#2962ff",
  "#5e35b1",
  "#673ab7",
  "#7e57c2",
  "#b39ddb",
  "#8c9eff",
  "#283593",
  "#311b92",
  "#4527a0",
  "#6a1b9a",
  "#9c27b0",
  "#8e24aa",
  "#ab47bc",
  "#7e57c2",
  "#d7aefb",
  "#ff80ab",
  "#f50057",
  "#c51162",
  "#512da8",
  "#5e35b1",
  "#3e2723",
  "#4e342e",
  "#5d4037",
  "#6d4c41",
  "#37474f",
  "#000000",
  "#ffffff",
];

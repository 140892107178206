import React from "react";
import { useTeam } from "contexts/team/hooks";
import { useOrganizationCurrentTeamQuery } from "shared/api/organization";
import { useTeamMembersQuery, useInvitationsQuery } from "shared/api";
import {
  DetailsSection,
  DeleteSection,
  InviteSupportUserSection,
} from "../shared";
import { Loader } from "components";
import {
  TeamInfoLimited,
  TeamMembers,
  TeamPendingInvitations,
  TeamRoles,
} from "./components";
import { Wrapper } from "./styles";
import { Features } from "constants/features";

const Organization = () => {
  const { isOwner, selectedTeamId, organizationId, selectedTeam, hasFeature } =
    useTeam();
  const { data: teamMembersData } = useTeamMembersQuery(selectedTeamId);
  const { data: teamPendingInvitationsData } = useInvitationsQuery(
    organizationId,
    isOwner()
  );
  const {
    data: teamInfoData,
    isLoading,
    refetch,
  } = useOrganizationCurrentTeamQuery(organizationId, selectedTeamId);

  const teamName = teamInfoData?.name as string;
  const currentMemberId = teamMembersData?.memberId;
  const memberRole = teamMembersData?.members.find(
    (member) => member.id === currentMemberId
  )?.role as string;

  if (!isOwner()) {
    return <TeamInfoLimited teamName={teamName} memberRole={memberRole} />;
  }

  if (isLoading) return <Loader />;
  if (!teamInfoData) return null;
  const hasInviteSupportMemberFeature = hasFeature(Features.SUPPORT_USER);

  return (
    <Wrapper>
      <DetailsSection
        currentEntity={teamInfoData}
        refetch={refetch}
        teamMembersData={teamMembersData}
      />

      {!selectedTeam?.parentTeamId && (
        <>
          <TeamMembers
            teamInfoData={teamInfoData}
            teamMembersData={teamMembersData}
            teamPendingInvitationsData={teamPendingInvitationsData}
          />

          <TeamPendingInvitations
            teamPendingInvitationsData={teamPendingInvitationsData}
          />

          <TeamRoles teamInfoData={teamInfoData} />

          {hasInviteSupportMemberFeature && <InviteSupportUserSection />}

          <DeleteSection currentEntity={teamInfoData} />
        </>
      )}
    </Wrapper>
  );
};

export default Organization;

import styled from "@emotion/styled";
import { theme } from "theme";

export const Header = styled.div`
  padding-right: ${theme.spacing.md};
  padding-left: ${theme.spacing.xl};
`;

export const Top = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.md};
`;

export const Bottom = styled.div`
  margin-bottom: ${theme.spacing.md};
`;

export const Buttons = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;

export const Title = styled.div`
  position: relative;
  width: 100%;
  margin-right: ${theme.spacing.sm};
  span {
    position: relative;
    padding-right: ${theme.spacing.sm};
    font-size: ${theme.font.size.body};
    background: ${theme.color.white};
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${theme.color.gray[300]};
  }
`;

export const SubTitle = styled.div`
  padding: 0 ${theme.spacing.xl};
  margin: ${theme.spacing.xl} 0 ${theme.spacing.md};
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.semiBold};
`;

import styled from "@emotion/styled";
import { theme } from "theme";

import ChevronDown from "assets/svg/ChevronDown.svg?react";
import { Popper } from "@mui/material";

export const FontSizeInput = styled.input`
  max-width: 22px;
  max-height: 20px;
  box-sizing: content-box;
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[300]};
  padding: 0 3px;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  appearance: textfield;
`;

export const FontInputWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 170px;
  flex: 1 0 auto;
`;

export const FontInput = styled.input<{ fontFamily?: string }>`
  box-sizing: content-box;
  border: none;
  padding: 0 3px;
  text-align: left;
  outline: none;
  padding-right: ${theme.spacing.lg};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  ${(p) => (p.fontFamily ? `font-family: ${p.fontFamily}` : "")};
`;

export const Icon = styled(ChevronDown)`
  position: absolute;
  right: 0;
  top: 50%;
  width: 14px;
  height: 14px;
  pointer-events: none;
  transform: translateY(-50%);
`;

export const StyledPopper = styled(Popper)`
  border-radius: ${theme.borderRadius};
  overflow: hidden;
`;

export const AutoCompleteOption = styled.li`
  &.Mui-focused,
  &:hover {
    background: ${theme.color.blue[100]} !important;
  }
`;

import styled from "@emotion/styled";
import CTAButton from "components/CTAButton/CTAButton";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  position: absolute;
  padding: var(--spacing-sm) var(--spacing-xxl) var(--spacing-sm)
    var(--spacing-md);
  background-color: var(--color-primary);
  width: 260px;
  border-radius: var(--label-border-radius);
  z-index: 1000;
  transition: opacity 0.25s;

  &::before {
    content: "";
    position: absolute;
    top: 12px;
    right: -4px;
    width: 10px;
    height: 10px;
    background-color: var(--color-primary);
    transform: rotate(45deg);
  }
`;

export const CloseButton = styled(CTAButton)`
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
`;

export const Text = styled.div`
  color: var(--color-white);
`;

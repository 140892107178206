/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDTO } from "../models/DocumentDTO";
import type { DocumentUpdateDTO } from "../models/DocumentUpdateDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DocumentService {
  /**
   * Add a document to a contract
   * @param organizationId
   * @param teamId
   * @param contractId
   * @param formData
   * @returns DocumentDTO
   * @throws ApiError
   */
  public static addDocument(
    organizationId: string,
    teamId: string,
    contractId: string,
    formData: {
      file?: Blob;
    }
  ): CancelablePromise<DocumentDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * Get all documents of a contract
   * @param organizationId
   * @param teamId
   * @param contractId
   * @returns DocumentDTO
   * @throws ApiError
   */
  public static getAllDocuments(
    organizationId: string,
    teamId: string,
    contractId: string
  ): CancelablePromise<Array<DocumentDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
      },
    });
  }

  /**
   * Get document of a contract by id
   * @param organizationId
   * @param teamId
   * @param contractId
   * @param documentId
   * @returns DocumentDTO
   * @throws ApiError
   */
  public static getDocument(
    organizationId: string,
    teamId: string,
    contractId: string,
    documentId: string
  ): CancelablePromise<DocumentDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
        documentId: documentId,
      },
    });
  }

  /**
   * Delete a document of a contract
   * @param organizationId
   * @param teamId
   * @param documentId
   * @param contractId
   * @returns DocumentDTO
   * @throws ApiError
   */
  public static deleteDocument(
    organizationId: string,
    teamId: string,
    documentId: string,
    contractId: string
  ): CancelablePromise<DocumentDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        documentId: documentId,
        contractId: contractId,
      },
    });
  }

  /**
   * Update document data
   * @param organizationId
   * @param teamId
   * @param documentId
   * @param contractId
   * @param requestBody
   * @returns DocumentDTO
   * @throws ApiError
   */
  public static updateDocumentData(
    organizationId: string,
    teamId: string,
    documentId: string,
    contractId: string,
    requestBody: DocumentUpdateDTO
  ): CancelablePromise<DocumentDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        documentId: documentId,
        contractId: contractId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Download document file
   * @param organizationId
   * @param teamId
   * @param contractId
   * @param documentId
   * @returns any
   * @returns binary
   * @throws ApiError
   */
  public static downloadDocument(
    organizationId: string,
    teamId: string,
    contractId: string,
    documentId: string
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}/file",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        contractId: contractId,
        documentId: documentId,
      },
    });
  }

  /**
   * Update the file from a contract
   * @param organizationId
   * @param teamId
   * @param documentId
   * @param contractId
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static updateDocumentFile(
    organizationId: string,
    teamId: string,
    documentId: string,
    contractId: string,
    formData: {
      file?: Blob;
    }
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}/file",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        documentId: documentId,
        contractId: contractId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * Fetch document OCR result
   * @param organizationId
   * @param teamId
   * @param documentId
   * @param contractId
   * @returns any
   * @throws ApiError
   */
  public static getDocumentOcrResult(
    organizationId: string,
    teamId: string,
    documentId: string,
    contractId: string
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}/ocr",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        documentId: documentId,
        contractId: contractId,
      },
    });
  }

  /**
   * Fetch document text
   * @param organizationId
   * @param teamId
   * @param documentId
   * @param contractId
   * @returns string
   * @throws ApiError
   */
  public static getDocumentText(
    organizationId: string,
    teamId: string,
    documentId: string,
    contractId: string
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/teams/{teamId}/contracts/{contractId}/documents/{documentId}/text",
      path: {
        organizationId: organizationId,
        teamId: teamId,
        documentId: documentId,
        contractId: contractId,
      },
    });
  }
}

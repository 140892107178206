import styled from "@emotion/styled";
import { theme } from "theme";

export const EditorInner = styled.div<{ zoom?: number; compact?: boolean }>`
  background-color: var(--color-white);
  position: relative;
  size: A4;
  box-shadow: ${theme.shadow.contractTemplate};

  ${({ compact }) =>
    compact &&
    `
    margin-top: ${theme.spacing.md};
    left: 50%;
    transform: translateX(-50%);
    width: 210mm;
  `}

  ${({ zoom }) => (zoom != null ? `zoom: ${zoom};` : "")}
`;

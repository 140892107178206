import { ContractDTOV1 } from "openapi";
import * as Sentry from "@sentry/react";

export const isUUID = (input: string) => {
  const regex = /^[a-z,0-9,-]{36,36}$/;
  return regex.test(input);
};

export const countVerificationStatus = (contract: ContractDTOV1) => {
  const fields = contract?.fields;

  if (!fields) return;

  let verifiedCount = 0;
  let unverifiedCount = 0;

  Object.values(fields).forEach((field) => {
    if (field.metadata) {
      Object.values(field.metadata).forEach(({ unverified }) =>
        unverified ? unverifiedCount++ : verifiedCount++
      );
    }
  });

  return { verified: verifiedCount, unverified: unverifiedCount };
};

export const readFileContents = (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as string);
      } else {
        resolve("");
      }
    };
    reader.onerror = () => resolve("");
    reader.readAsText(file);
  });
};

export const getTemplateObj = async (file?: Blob) => {
  if (!file) return;

  try {
    const fileContents = await readFileContents(file);

    if (!fileContents) return;

    return JSON.parse(fileContents) as {
      templateId: string;
      content: string;
    };
  } catch (error) {
    Sentry.captureException(error);
  }
};

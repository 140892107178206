import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Header,
  Title,
  Wrapper,
  Top,
  Bottom,
  PreviewContainer,
  Container,
} from "./styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DocumentListItem from "../../../../new-components/DocumentListItem/DocumentListItem";
import { useTranslation } from "react-i18next";
import { CTAButton } from "components";
import SearchBar from "new-components/TextEditor/components/SearchBar";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Plus from "../../../../assets/svg/plus.svg?react";
import ImportDocument from "./ImportDocument/ImportDocument";
import { DocumentDTO } from "openapi/models/DocumentDTO";
import {
  useDeleteDocumentMutation,
  useFetchDocumentsQuery,
  useUpdateDocumentDataMutation,
} from "shared/api/documents";
import { useTeam } from "contexts/team/hooks";
import { useParams } from "react-router";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import BinIcon from "assets/svg/bin-icon.svg?react";
import DownloadIcon from "assets/svg/download-icon.svg?react";
import PreviewIcon from "assets/svg/preview-icon.svg?react";
import { Tooltip } from "@mui/material";
import DeleteDocumentModal from "pages/Contract/Modals/DeleteDocument";
import { useTeamMembersQuery } from "shared/api/team-members";
import saveAs from "file-saver";
import { DocumentService } from "openapi/services/DocumentService";
import Document from "../Document/Document";
import { useSnackbar } from "notistack";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AcceptedFileType } from "../../../../shared/enums/document.enum";
import { isAcceptedFileType } from "constants/utils";
import { getNewOrder } from "./Documents-utils";
import { CardWrapper } from "../Document/styles";
import CircularProgress from "@mui/material/CircularProgress";

type DocumentsProps = {
  contractTeamId: string;
};

const Documents = ({ contractTeamId }: DocumentsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId, selectedTeamId } = useTeam();
  const { id: contractId } = useParams();

  const { data: documents, isLoading } = useFetchDocumentsQuery(
    organizationId,
    contractTeamId,
    contractId
  );

  const { mutateAsync: updateDocumentData } = useUpdateDocumentDataMutation();

  const { data: teamMemberData } = useTeamMembersQuery(selectedTeamId);

  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showAddDocumentModal, setShowAddDocumentModal] =
    useState<boolean>(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDocumentList, setShowDocumentList] = useState<boolean | undefined>(
    undefined
  );
  const [selectedDocument, setSelectedDocument] = useState<DocumentDTO>();
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentDTO[]>([]);
  const { mutateAsync: deleteDocument } = useDeleteDocumentMutation();

  const handleModal = () => {
    setShowAddDocumentModal((prevProps) => !prevProps);
  };

  const handleSearch = () => {
    setShowSearch((prevProps) => !prevProps);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (contractId && active && over && active.id !== over.id) {
      const oldIndex = filteredDocuments.findIndex(
        (document) => document.id === active.id
      );
      const newIndex = filteredDocuments.findIndex(
        (document) => document.id === over.id
      );

      const reorderedDocument = arrayMove(
        filteredDocuments,
        oldIndex,
        newIndex
      );
      setFilteredDocuments(reorderedDocument);

      const newOrder = getNewOrder(reorderedDocument, newIndex);

      try {
        await updateDocumentData({
          organizationId,
          teamId: contractTeamId,
          documentId: active.id as string,
          data: { order: newOrder },
          contractId,
        });
      } catch (e) {
        enqueueSnackbar({
          message: t("common.errors.unexpected"),
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setFilteredDocuments(documents || []);
  }, [showSearch]);

  useEffect(() => {
    if (isLoading || !documents) return;
    setFilteredDocuments(documents);

    if (documents.length === 0) {
      setShowDocumentList(false);
      return;
    }

    if (showDocumentList === true) {
      return;
    }

    if (documents.length === 1 && isPreview(documents[0])) {
      previewDocument(documents[0]);
      setShowDocumentList(false);
      return;
    }

    setShowDocumentList(true);
  }, [isLoading, documents]);

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredItems = documents?.filter((document) =>
      document?.filename?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (filteredItems) setFilteredDocuments(filteredItems);
  };

  const getAuthorName = (authorId: string | null) => {
    if (authorId) {
      const author = teamMemberData?.members?.find(
        (member) => member.id === authorId
      );
      const authorName = `${author?.firstname ? author.firstname : ""} ${
        author?.lastname ? author.lastname : ""
      }`;
      return authorName;
    }
    return "";
  };

  const previewDocument = (document: DocumentDTO) => {
    setSelectedDocument(document);
    setShowDocumentList(false);
  };

  const isPreview = (document: DocumentDTO) => {
    return (
      document.mimetype === AcceptedFileType.PDF ||
      document.mimetype === AcceptedFileType.LEXICAL
    );
  };

  const downloadDocument = async (document: DocumentDTO) => {
    try {
      if (!contractId) {
        return;
      }

      const documentFileBlob: Blob | undefined =
        await DocumentService.downloadDocument(
          organizationId,
          contractTeamId,
          contractId,
          document.id
        );
      if (documentFileBlob) {
        saveAs(documentFileBlob, document.filename);
      }
    } catch (err) {
      enqueueSnackbar(t("common.errors.unexpected"), {
        variant: "error",
      });
    }
  };

  const handleOnDeleteDocument = async () => {
    if (selectedDocument) {
      const documentId = selectedDocument.id;
      await deleteDocument(
        {
          organizationId,
          teamId: contractTeamId,
          contractId: contractId,
          documentId,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              t("pages.contractDetails.documents.deleteSuccess"),
              { variant: "success" }
            );
          },
          onError: (error) => {
            enqueueSnackbar(t("pages.contractDetails.documents.deleteError"), {
              variant: "error",
            });
          },
        }
      );
      setSelectedDocument(undefined);
      setShowDeleteDocumentModal(false);
    }
  };

  const confirmDelete = (document: DocumentDTO) => {
    setSelectedDocument(document);
    setShowDeleteDocumentModal(true);
  };

  const resetStates = () => {
    setShowDeleteDocumentModal(false);
    setShowDocumentList(true);
    setSelectedDocument(undefined);
  };

  if (!documents) {
    return (
      <CardWrapper data-testid="document-loader">
        <CircularProgress />
      </CardWrapper>
    );
  }

  // Quick fix for deleting newly uploaded single document. Find a better way
  const getSelectedDocument = () => {
    if (!showDocumentList && documents?.length === 1) {
      return documents[0];
    }
    return selectedDocument;
  };

  return (
    <Container>
      {showDocumentList && (
        <PreviewContainer>
          <Header>
            <Top>
              <Title>
                <span>{t("pages.contractDetails.folders.documents")}</span>
              </Title>
              <CTAButton
                variant="tertiary"
                icon={<Plus />}
                size="tiny"
                onClick={handleModal}
              />
              <CTAButton
                variant="tertiary"
                icon={<SearchOutlinedIcon />}
                size="tiny"
                onClick={handleSearch}
              />
            </Top>
            {showSearch && (
              <Bottom>
                <SearchBar onChange={onFilterChange} />
              </Bottom>
            )}
          </Header>
          <OverlayScrollbarsComponent
            defer
            style={{
              overflowX: "auto",
            }}
          >
            <Wrapper>
              <DndContext
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                {filteredDocuments && (
                  <SortableContext
                    items={filteredDocuments}
                    strategy={verticalListSortingStrategy}
                  >
                    {filteredDocuments.map((document) => {
                      const shallPreview = isAcceptedFileType(
                        document.mimetype
                      );
                      return (
                        <DocumentListItem
                          id={document.id}
                          key={document.id}
                          listItem={{
                            createdAt: document.createdAt,
                            categoryId: "",
                            name: document.filename,
                          }}
                          authorName={getAuthorName(document.authorId)}
                          onClick={
                            isPreview(document)
                              ? () => previewDocument(document)
                              : undefined
                          }
                          buttons={
                            <>
                              <Tooltip
                                placement="top"
                                title={t("common.buttons.download")}
                              >
                                <CTAButton
                                  variant="tertiary"
                                  icon={<DownloadIcon />}
                                  iconSize="medium"
                                  onClick={() => downloadDocument(document)}
                                />
                              </Tooltip>
                              {shallPreview && (
                                <Tooltip
                                  placement="top"
                                  title={t("common.buttons.preview")}
                                >
                                  <CTAButton
                                    variant="tertiary"
                                    icon={<PreviewIcon />}
                                    iconSize="medium"
                                    onClick={() => previewDocument(document)}
                                  />
                                </Tooltip>
                              )}
                              <Tooltip
                                placement="top"
                                title={t("common.buttons.delete")}
                              >
                                <CTAButton
                                  variant="tertiary"
                                  icon={<BinIcon />}
                                  iconSize="medium"
                                  onClick={() => confirmDelete(document)}
                                />
                              </Tooltip>
                            </>
                          }
                          isDraggable={true}
                        />
                      );
                    })}
                  </SortableContext>
                )}
              </DndContext>
            </Wrapper>
          </OverlayScrollbarsComponent>
        </PreviewContainer>
      )}
      {showAddDocumentModal && (
        <ImportDocument
          documents={documents}
          contractTeamId={contractTeamId}
          showAddDocumentModal={showAddDocumentModal}
          handleClose={() => setShowAddDocumentModal(false)}
        />
      )}
      {showDeleteDocumentModal && (
        <DeleteDocumentModal
          open={showDeleteDocumentModal}
          handleClose={() => setShowDeleteDocumentModal(false)}
          handleOnDeleteDocument={handleOnDeleteDocument}
        />
      )}
      {!showDocumentList && (
        <>
          <Document
            selectedDocument={selectedDocument}
            onBackClick={() => resetStates()}
            onDeleteClick={() =>
              confirmDelete(getSelectedDocument() as DocumentDTO)
            }
            onDownloadClick={() =>
              downloadDocument(getSelectedDocument() as DocumentDTO)
            }
          />
        </>
      )}
    </Container>
  );
};

export default Documents;

import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { CTAButton, NewModal } from "components";
import { theme } from "theme";
import {
  ModalBody,
  ModalBodyUpload,
  Content,
  Block,
  Paragraph,
  Wrapper,
  ContentBlock,
  ErrorAlert,
} from "./styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { DocumentDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useParams } from "react-router-dom";
import { useAddDocumentMutation } from "shared/api//documents";
import CloseIcon from "@mui/icons-material/Close";
import { acceptedDocumentTypes } from "shared/constants/constants";
import { enqueueSnackbar } from "notistack";

interface TemplateFile extends File {
  path?: string;
}

const importLimit = 30;

const ImportDocument = ({
  documents = [],
  contractTeamId,
  showAddDocumentModal,
  handleClose,
}: {
  documents: DocumentDTO[] | undefined;
  contractTeamId: string;
  importedFile?: File | null;
  showAddDocumentModal: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [templateFiles, setTemplateFiles] = useState<TemplateFile[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { organizationId } = useTeam();
  const { mutateAsync } = useAddDocumentMutation();

  useEffect(() => {
    setErrorMessage("");
    setTemplateFiles([]);
    if (documents.length >= importLimit) {
      setErrorMessage(
        t(
          `pages.contractDetails.documents.addModal.DocumentPerContractLimitReachedException`
        )
      );
    }
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: TemplateFile[]) => {
      setErrorMessage("");
      if (
        templateFiles.length + documents.length + acceptedFiles.length >
        importLimit
      ) {
        setErrorMessage(
          t(
            `pages.contractDetails.documents.addModal.DocumentPerContractLimitReachedException`
          )
        );
        return;
      }
      setTemplateFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [templateFiles]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: acceptedDocumentTypes,
    multiple: true,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const handleRemoveFile = (fileName: string) => {
    setTemplateFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file.name !== fileName);

      // Check if after removing the file, the limit is no longer reached
      if (updatedFiles.length + documents.length < importLimit) {
        setErrorMessage("");
      }

      return updatedFiles;
    });
  };

  const handleSubmit = async () => {
    if (templateFiles.length && id) {
      const successfullyUploadedFiles: File[] = [];
      let errorMessageText = "";
      setIsLoading(true);
      try {
        for (const file of templateFiles) {
          try {
            await mutateAsync({
              organizationId,
              teamId: contractTeamId,
              contractId: id,
              file,
            });
            successfullyUploadedFiles.push(file);
          } catch (err: unknown) {
            if (typeof err === "object" && err !== null) {
              const errorResponse = (err as { body?: { error?: string } }).body;

              if (errorResponse?.error) {
                const error = errorResponse.error;
                if (error === "DocumentPerContractLimitReachedException") {
                  errorMessageText = t(
                    `pages.contractDetails.documents.addModal.DocumentPerContractLimitReachedException`
                  );
                  break; // Stop further uploads if this error occurs, as no more files can be uploaded
                } else {
                  errorMessageText = t(
                    `pages.contractDetails.documents.addModal.unexpectedError`
                  );
                }
              }
            }
          }
        }

        // Remove successfully uploaded files from templateFiles
        const remainingFiles = templateFiles.filter(
          (file) => !successfullyUploadedFiles.includes(file)
        );

        if (remainingFiles.length === 0) {
          enqueueSnackbar(
            t("pages.contractDetails.documents.addModal.successUpload"),
            {
              variant: "success",
            }
          );
          handleClose();
        } else {
          setTemplateFiles(remainingFiles);
          setErrorMessage(
            errorMessageText +
              " " +
              t(`pages.contractDetails.documents.addModal.uploadFailure`)
          );
        }
        setIsLoading(false);
      } catch (globalErr) {
        setErrorMessage(
          t(`pages.contractDetails.documents.addModal.unexpectedError`)
        );
        setIsLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    if (isLoading) {
      return;
    }
    handleClose();
  };

  return (
    <>
      <NewModal
        fullWidth
        open={showAddDocumentModal}
        handleClose={handleCloseModal}
        title={t("pages.contractDetails.documents.addModal.title")}
        body={
          <ModalBody>
            {errorMessage && (
              <ErrorAlert variant="filled" severity="error">
                {errorMessage}
              </ErrorAlert>
            )}
            {/* Todo: handle disabling of drop area */}
            <ModalBodyUpload>
              <Content {...getRootProps()}>
                <input {...getInputProps()} ref={fileInputRef} />
                <ContentBlock>
                  <Block>
                    <Paragraph bold>
                      {t(
                        "pages.contractDetails.documents.addModal.description"
                      )}
                    </Paragraph>
                    <Paragraph>
                      {t("pages.contractDetails.documents.addModal.caption")}
                    </Paragraph>
                  </Block>
                </ContentBlock>
              </Content>
            </ModalBodyUpload>
            <OverlayScrollbarsComponent
              defer
              style={{
                overflowX: "auto",
                marginTop: "36px",
              }}
            >
              <Wrapper>
                {templateFiles.length > 0 && (
                  <div
                    style={{
                      fontWeight: `${theme.font.weight.semiBold}`,
                    }}
                  >{`${templateFiles.length} ${t(
                    "pages.contractDetails.documents.addModal.filesAdded"
                  )}`}</div>
                )}
                <List sx={{ width: "100%" }}>
                  {templateFiles.map((document, index) => (
                    <ListItem
                      style={{
                        paddingLeft: "8px",
                      }}
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton aria-label="comment" disabled={isLoading}>
                          <CloseIcon
                            color="primary"
                            onClick={() => handleRemoveFile(document.name)}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={document.name} />
                    </ListItem>
                  ))}
                </List>
              </Wrapper>
            </OverlayScrollbarsComponent>
          </ModalBody>
        }
        footer={
          <>
            <CTAButton
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={handleClose}
              disabled={isLoading}
            />
            <CTAButton
              name={t("common.buttons.import")}
              onClick={handleSubmit}
              disabled={!templateFiles.length || isLoading}
            />
          </>
        }
      />
    </>
  );
};

export default ImportDocument;

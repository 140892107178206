import React from "react";
import { Autocomplete, Tooltip } from "@mui/material";
import { LexicalEditor } from "lexical";
import {
  FontInputWrapper,
  FontInput,
  Icon,
  StyledPopper,
  AutoCompleteOption,
} from "./styles";
import {
  formatBulletList,
  formatCheckList,
  formatHeading,
  formatNumberedList,
  formatParagraph,
} from "../utils";
import { useTranslation } from "react-i18next";

type FormatSelectorProps = {
  editor: LexicalEditor;
  value: BlockType;
  disabled?: boolean;
};

export const BLOCK_TYPES = [
  "paragraph",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "number",
  "bullet",
  "check",
] as const;
export type BlockType = (typeof BLOCK_TYPES)[number];

export const FormatSelector = (props: FormatSelectorProps) => {
  const { t } = useTranslation();
  const options = BLOCK_TYPES.map((value) => ({
    value: value,
    label: t(`textEditor.plugins.toolbar.blockType.${value}`),
  }));

  const activeOption = options.find((option) => option.value === props.value);

  const handleClick = (option: BlockType) => {
    switch (option) {
      case "paragraph":
        formatParagraph(props.editor);
        break;
      case "number":
        formatNumberedList(props.editor, props.value);
        break;
      case "bullet":
        formatBulletList(props.editor, props.value);
        break;
      case "check":
        formatCheckList(props.editor, props.value);
        break;
      default:
        formatHeading(props.editor, props.value, option);
        break;
    }
  };

  return (
    <Autocomplete
      sx={{ width: "160px" }}
      disabled={props.disabled}
      options={options}
      disableClearable
      onChange={(e, option) => {
        handleClick(option.value);
      }}
      value={activeOption}
      PopperComponent={StyledPopper}
      renderInput={(params) => (
        <Tooltip
          title={t("textEditor.plugins.toolbar.fontStyle")}
          disableHoverListener={props.disabled}
          disableFocusListener={props.disabled}
          disableTouchListener={props.disabled}
        >
          <FontInputWrapper ref={params.InputProps.ref}>
            <FontInput {...params.inputProps} />
            <Icon />
          </FontInputWrapper>
        </Tooltip>
      )}
      renderOption={(props, option) => (
        <AutoCompleteOption {...props}>{option.label}</AutoCompleteOption>
      )}
    />
  );
};

export const setCookie = (name: string, value: boolean, days: number) => {
  document.cookie = `${name}=${encodeURIComponent(value)}${
    days ? `; expires=${new Date(Date.now() + days * 864e5).toUTCString()}` : ""
  }; path=/`;
};

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(";")[0];
    return cookieValue ? decodeURIComponent(cookieValue) : undefined;
  }

  return undefined;
};

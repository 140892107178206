import styled from "@emotion/styled";
import { theme } from "theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e9e9ed;
  border-top: 1px solid #e9e9ed;
  height: 32px;
  padding-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
`;

export const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: ${theme.font.size.body};
    color: var(--text-secondary);
  }
`;

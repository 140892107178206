import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractNameDto, ContractDTOV1 } from "openapi";
import { CTAButton, NewModal } from "components";
import ContractLinkModalContent from "pages/Contract/Modals/LinkContract";
import { Tooltip } from "@mui/material";
import LinkContractIcon from "../../../../../assets/svg/link-contract-icon.svg?react";

type Props = {
  selectedContract?: ContractDTOV1;
  contractGroup: ContractNameDto[];
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
};

const LinkContract = ({
  selectedContract,
  contractGroup,
  fetchData,
}: Props) => {
  const [showLinkContractModal, setShowLinkContractModal] = useState(false);
  const { t } = useTranslation();

  const handleContractModalToLink = () => setShowLinkContractModal(true);
  const handleContractModalToLinkClose = () => setShowLinkContractModal(false);

  return (
    <>
      <Tooltip title={t("pages.contractDetails.buttons.linkExistingContracts")}>
        <CTAButton
          variant="tertiary"
          icon={<LinkContractIcon />}
          size="tiny"
          onClick={handleContractModalToLink}
          label="contractLinkBtn"
        />
      </Tooltip>

      <NewModal
        open={showLinkContractModal}
        handleClose={handleContractModalToLinkClose}
        title={t("pages.contractDetails.modals.linkContract.title")}
        body={
          <ContractLinkModalContent
            handleClose={handleContractModalToLinkClose}
            selectedContract={selectedContract}
            contractGroup={contractGroup}
            refetchData={fetchData}
          />
        }
      />
    </>
  );
};

export default LinkContract;

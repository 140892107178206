/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: ^ Remove rule above once the template sidebar is properly implemented
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import {
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import theme from "./theme";
import ToolbarPlugin from "./plugins/ToolbarPlugin/ToolbarPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import { Placeholder } from "./components/Editor/Placeholder";
import {
  Components,
  EditorContainer,
  Sidebar,
  TemplateComponents,
  Title,
} from "./components/Editor/EditorContainer";
import { EditorInner } from "./components/Editor/EditorInner";
import { ContentEditable } from "./components/Editor/ContentEditable";
import TemplateComponent from "./components/PlaceholderComponent/PlaceholderComponent";
import TemplatePlugin from "./plugins/TemplatePlugin";
import TableHoverActionsPlugin from "./plugins/TableHoverActionsPlugin";
import { PlaceholderTextNode } from "./nodes/PlaceholderTextNode";

import { ListNode, ListItemNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoLinkNode } from "@lexical/link";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

import "./styles.css";
import { ImageNode } from "./nodes/ImageNode";
import ImagesPlugin from "./plugins/ImagePlugin";
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import { PageBreakNode } from "./nodes/PageBreakNode";
import PageBreakPlugin from "./plugins/PageBreakPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { FloatingPlaceholderPopupPlugin } from "./plugins/FloatingPlaceholderPopupPlugin";
import { useFieldsQuery } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { Loader } from "components";
import { CategoryDTO, ContractFieldDTOV1 } from "openapi";
import SearchBar from "./components/SearchBar";
import { LexicalEditor } from "lexical";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useLocale } from "hooks";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { CheckListPlugin } from "./plugins/CheckListPlugin/CheckListPlugin";
import { Features } from "constants/features";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: unknown) {
  console.error(error);
  Sentry.captureException(error);
}

export const getFieldsByType = (type: ContractFieldDTOV1.type) => {
  switch (type) {
    case ContractFieldDTOV1.type.DURATION:
      return [
        "type",
        "startDate",
        "endDate",
        "interval",
        "terminationDate",
        "noticePeriod",
        "automaticRenewal",
      ];
    case ContractFieldDTOV1.type.AMOUNT:
      return ["value", "currency"];
    case ContractFieldDTOV1.type.CONTACT:
      return [
        "name",
        "phone",
        "email",
        "address",
        "address2",
        "postalCode",
        "country",
        "notes",
        "vatId",
        "city",
        "numberOfEmployees",
        "website",
      ];
    default:
      return ["value"];
  }
};

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
      // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
    };
  },
];

type OnChangeProps = Parameters<typeof OnChangePlugin>[0];

type EditorProps = {
  initialState?: InitialEditorStateType;
  editorRef?: { current: null | LexicalEditor };
  onChange?: OnChangeProps["onChange"];
  onChangeSettings?: Omit<OnChangeProps, "onChange">;
  compact?: boolean;
  readOnly?: boolean;
  currentCategory?: CategoryDTO | undefined;
  showSidebar?: boolean;
};

function Editor(props: EditorProps) {
  const initialConfig = {
    namespace: "MyEditor",
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      PlaceholderTextNode,
      AutoLinkNode,
      ImageNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      PageBreakNode,
    ],
    theme,
    onError,
    editorState: props.initialState,
    compact: props.compact,
    showSidebar: props.showSidebar,
  };

  const { organizationId, hasFeature } = useTeam();
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { data: fields, isLoading: fieldsLoading } =
    useFieldsQuery(organizationId);

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  const [zoom] = useState(1);
  const [filteredFields, setFilteredFields] = useState<
    ContractFieldDTOV1[] | null | undefined
  >([]);

  const availableFields = useMemo(() => {
    const availableFieldIds = new Set(
      props.currentCategory?.sections
        .map((section) => section.fields.map((field) => field.id))
        .flat()
    );

    return fields?.filter((field) => availableFieldIds?.has(field.id));
  }, [fields, props.currentCategory]);

  useEffect(() => {
    setFilteredFields(availableFields);
  }, [availableFields]);

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredItems = availableFields?.filter((item) =>
      item?.name[locale]?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (filteredItems) setFilteredFields(filteredItems);
  };

  // TODO: we should move this out ouf TextEditor and pass into editor props instead
  const getLocalizedPlaceholderData = (
    slug: string,
    fieldKey: string
  ): { slug: string | null; fieldKey: string | null } => {
    const field = fields?.find((field) => field.visibleId === slug);

    if (!field) return { slug: null, fieldKey: null };

    const res: { slug: string | null; fieldKey: string | null } = {
      slug: field.name[locale],
      fieldKey: null,
    };

    if (fieldKey !== "value") {
      switch (field.type) {
        case ContractFieldDTOV1.type.CONTACT:
          res.fieldKey = t(`pages.contacts.table.columnNames.${fieldKey}`);
          break;
        case ContractFieldDTOV1.type.DURATION:
          res.fieldKey = t(`pages.import.labels.duration.${fieldKey}`);
          break;
        case ContractFieldDTOV1.type.AMOUNT:
          res.fieldKey = t(`pages.contracts.tableColumns.${fieldKey}`);
          break;
        default:
          break;
      }
    }

    return res;
  };

  const isLoading = !fields || fieldsLoading;

  if (isLoading) return <Loader />;

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorContainer>
        <OverlayScrollbarsComponent
          defer
          style={{
            position: "relative",
            flex: "1 1 auto",
          }}
        >
          <div>
            <ToolbarPlugin />
            <EditorInner zoom={zoom} compact={props.compact}>
              <RichTextPlugin
                contentEditable={
                  <div className="editor" ref={onRef}>
                    <ContentEditable />
                  </div>
                }
                placeholder={
                  <Placeholder>{t(`textEditor.emptyPlaceholder`)}</Placeholder>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ListPlugin />
              <AutoFocusPlugin />
              <AutoLinkPlugin matchers={MATCHERS} />
              <ImagesPlugin />
              <TablePlugin hasCellMerge hasCellBackgroundColor />
              <TabIndentationPlugin />
              <TableCellResizer />
              <TableHoverActionsPlugin />
              <TableActionMenuPlugin />
              <PageBreakPlugin />
              <CheckListPlugin />
              <TemplatePlugin
                getLocalizedLabelParts={getLocalizedPlaceholderData}
                onFillPlaceholder={({ origin, slug, value }) => void 0}
              />
              {props.editorRef !== undefined && (
                <EditorRefPlugin editorRef={props.editorRef} />
              )}
              {props.onChange && (
                <OnChangePlugin
                  {...props.onChangeSettings}
                  onChange={props.onChange}
                />
              )}
              {floatingAnchorElem && (
                <FloatingPlaceholderPopupPlugin
                  getLocalizedLabelParts={getLocalizedPlaceholderData}
                  anchorEl={floatingAnchorElem}
                />
              )}
            </EditorInner>
          </div>
        </OverlayScrollbarsComponent>
        {hasFeature(Features.CONTRACT_TEMPLATES_PLACEHOLDERS) &&
          props.showSidebar && (
            <Sidebar>
              <SearchBar onChange={onFilterChange} />
              <TemplateComponents>
                <Title>
                  <span>{t("common.headers.fields")}</span>
                </Title>
                <OverlayScrollbarsComponent defer>
                  <Components>
                    {filteredFields?.flatMap((datapoint) =>
                      getFieldsByType(datapoint.type).map((field) => (
                        <TemplateComponent
                          key={datapoint.id + field}
                          fieldKey={field}
                          draggable
                          type={datapoint.type}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          slug={datapoint.visibleId!}
                          label={datapoint.name[locale]}
                          getLocalizedLabelParts={getLocalizedPlaceholderData}
                        />
                      ))
                    )}
                  </Components>
                </OverlayScrollbarsComponent>
              </TemplateComponents>
            </Sidebar>
          )}
      </EditorContainer>
    </LexicalComposer>
  );
}

export default Editor;

import React, { useMemo } from "react";
import { Date, Header, Title, Wrapper, Body } from "./styles";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import {
  AttachmentData,
  ContractChangeLogDTO,
  ContractFieldDTOV1,
  ContractFieldDto,
  ContractMovedData,
  DocumentData,
  RecipientDto,
  SignatureRequestCancelDTO,
  SignatureRequestDTO,
  SignerData,
  SignerDeclinedData,
  TaskData,
  UserInfoDto,
} from "openapi";
import dayjs from "dayjs";
import { useUserInfo } from "hooks";
import { useTranslation } from "react-i18next";
import FieldWithIconEntityItem from "../FieldWithIconEntityItem/FieldWithIconEntityItem";
import { dateFields, fieldsToSkip } from "../../utils";
import {
  ChangeLogDataSimpleItemType,
  ChangeLogDataItemType,
  ChangeLogDataType,
  LogEntityProps,
} from "../../types";
import { Loader } from "components";
import LogIcon from "assets/svg/log-icon.svg?react";
import TagIcon from "assets/svg/tag-icon.svg?react";
import SimpleField from "../Fields/SimpleField";
import DateField from "../Fields/DateField";
import CategoryField from "../Fields/CategoryField";
import ContactField from "../Fields/ContactField";
import DurationField from "../Fields/DurationField";
import AmountField from "../Fields/AmountField";
import Tags from "../Tags/Tags";
import { AcceptedFileType } from "shared/enums/document.enum";

import BankNoteIcon from "assets/svg/bank-note-02.svg?react";
import NumberIcon from "assets/svg/number.svg?react";
import StarAiIcon from "assets/svg/star-AI.svg?react";
import ClockIcon from "assets/svg/clock.svg?react";
import TypeIcon from "assets/svg/type-01.svg?react";
import LinkIcon from "assets/svg/link-01.svg?react";
import UserIcon from "assets/svg/user-03.svg?react";
import MarkerIcon from "assets/svg/marker-pin-02.svg?react";
import CalendarIcon from "assets/svg/calendar.svg?react";
import ListBulletIcon from "assets/svg/list-bullet.svg?react";

export const getFieldIcon = (
  fieldType: ContractFieldDTOV1.type | undefined
) => {
  switch (fieldType) {
    case ContractFieldDTOV1.type.AMOUNT: {
      return <BankNoteIcon />;
    }
    case ContractFieldDTOV1.type.NUMBER: {
      return <NumberIcon />;
    }
    case ContractFieldDTOV1.type.FORMULA: {
      return <StarAiIcon />;
    }
    case ContractFieldDTOV1.type.DURATION: {
      return <ClockIcon />;
    }
    case ContractFieldDTOV1.type.TEXTAREA:
    case ContractFieldDTOV1.type.TEXTFIELD: {
      return <TypeIcon />;
    }
    case ContractFieldDTOV1.type.LINK: {
      return <LinkIcon />;
    }
    case ContractFieldDTOV1.type.LIST: {
      return <ListBulletIcon />;
    }
    case ContractFieldDTOV1.type.COUNTRY: {
      return <MarkerIcon />;
    }
    case ContractFieldDTOV1.type.CONTACT: {
      return <UserIcon />;
    }
    case ContractFieldDTOV1.type.DATE: {
      return <CalendarIcon />;
    }
    default: {
      return <LogIcon />;
    }
  }
};

export const getChangedFields = (
  data: ChangeLogDataItemType,
  fieldVisibleId: string,
  userInfo: UserInfoDto | undefined
): ChangeLogDataType => {
  const result: ChangeLogDataType = {};
  const newObj: Record<string, string | undefined> = data.to || {};
  const oldObj: Record<string, string | undefined> = data.from || {};

  const newKeys = Object.keys(newObj);
  const oldKeys = Object.keys(oldObj);

  const allKeys = new Set([...newKeys, ...oldKeys]);

  allKeys.forEach((key) => {
    if (newObj[key] !== oldObj[key]) {
      const fieldSlug = key === "value" ? fieldVisibleId : key;
      const isDateField = dateFields.find((item) => item === fieldSlug);
      result[fieldSlug] = {
        from: {
          value:
            isDateField && oldObj[key]
              ? dayjs(oldObj[key])?.format(userInfo?.dateFormat)
              : oldObj[key],
        },
        to: {
          value:
            isDateField && newObj[key]
              ? dayjs(newObj[key])?.format(userInfo?.dateFormat)
              : newObj[key],
        },
      };
    }
  });
  return result;
};

const LogEntity = ({
  changeLog,
  categories,
  teams,
  fields,
  contacts,
  teamMembers,
  currentUserId,
}: LogEntityProps) => {
  const { userInfo } = useUserInfo();
  const { t } = useTranslation();

  const changeLogData = changeLog.data;
  const eventType = changeLog.event;

  const title = useMemo(() => {
    const changeLogLocale = "pages.contractDetails.changeLog";
    const entityTitlesLocale = `${changeLogLocale}.entityTitles`;

    const currentUserIsAuthor = currentUserId === changeLog.authorId;

    const author = teamMembers?.find(
      (member) => member.id === changeLog.authorId
    );

    const fullName = currentUserIsAuthor
      ? `${author?.firstname as string} ${author?.lastname as string} (${t(
          `${changeLogLocale}.misc.you`
        )})`
      : `${author?.firstname as string} ${author?.lastname as string}`;

    const user = fullName ? fullName : t(`${changeLogLocale}.misc.deletedUser`);

    if (
      eventType === ContractChangeLogDTO.event.DOCUMENT_UPLOADED ||
      eventType === ContractChangeLogDTO.event.DOCUMENT_REMOVED
    ) {
      const fileType = (changeLogData as DocumentData)?.type;
      let event = eventType as string;
      if (
        fileType === AcceptedFileType.LEXICAL ||
        (changeLogData as DocumentData).fileName.includes("draft")
      ) {
        event =
          eventType === ContractChangeLogDTO.event.DOCUMENT_UPLOADED
            ? "TEMPLATE_USED"
            : "TEMPLATE_REMOVED";
      }
      return t(`${entityTitlesLocale}.${event}`, {
        author: user,
      });
    }

    if (ContractChangeLogDTO.event[eventType]) {
      return t(`${entityTitlesLocale}.${eventType}`, { author: user });
    }

    return t(`${entityTitlesLocale}.default`);
  }, [
    currentUserId,
    changeLog.authorId,
    changeLogData,
    teamMembers,
    eventType,
    t,
  ]);

  const renderContractInitiated = () => {
    return Object.keys(changeLogData).map((key, index) => {
      const item = (
        changeLogData as Record<string, string | Record<string, string>>
      )[key];

      if (fieldsToSkip.includes(key) || !item) return null;

      const field = fields?.find((field) => field.visibleId === key);
      const fieldData = {
        to: item,
      };

      if (key === "categoryId") {
        return (
          <CategoryField
            fieldData={fieldData as ChangeLogDataSimpleItemType}
            categories={categories}
            key={index}
            variant="row"
          />
        );
      }

      if (key === "partnerCompany") {
        return (
          <ContactField
            fieldData={fieldData as ChangeLogDataItemType}
            contacts={contacts}
            key={index}
            variant="row"
          />
        );
      }

      if (key === "tags") {
        return (
          <Tags
            fieldData={fieldData as ChangeLogDataSimpleItemType}
            fieldIcon={<TagIcon />}
            variant="row"
            key={index}
          />
        );
      }
      switch (field?.type) {
        case ContractFieldDto.type.DURATION: {
          return (
            <DurationField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              userInfo={userInfo}
              variant="row"
              key={index}
            />
          );
        }
        case ContractFieldDto.type.AMOUNT: {
          return (
            <AmountField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              userInfo={userInfo}
              field={field}
              variant="row"
              key={index}
            />
          );
        }
        case ContractFieldDto.type.DATE: {
          return (
            <DateField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              fields={fields}
              variant="row"
              key={index}
            />
          );
        }
        default:
          return (
            <SimpleField
              fieldData={fieldData as ChangeLogDataItemType}
              fieldVisibleId={key}
              field={field}
              variant="row"
              key={index}
            />
          );
      }
    });
  };

  const renderContractEdited = () => {
    return Object.keys(changeLogData).map((key, index) => {
      const item = (changeLogData as Record<string, ChangeLogDataType>)[key];
      if (fieldsToSkip.includes(key) || !item) return null;

      const field = fields?.find((field) => field.visibleId === key);

      if (key === "categoryId") {
        return (
          <CategoryField
            fieldData={item as ChangeLogDataSimpleItemType}
            categories={categories}
            key={index}
          />
        );
      }

      if (key === "partnerCompany") {
        return (
          <ContactField fieldData={item} contacts={contacts} key={index} />
        );
      }

      if (key === "tags") {
        return <Tags fieldData={item} fieldIcon={<TagIcon />} key={index} />;
      }

      switch (field?.type) {
        case ContractFieldDto.type.DURATION: {
          return (
            <DurationField
              fieldData={item}
              fieldVisibleId={key}
              userInfo={userInfo}
            />
          );
        }
        case ContractFieldDto.type.AMOUNT: {
          return (
            <AmountField
              fieldData={item}
              fieldVisibleId={key}
              userInfo={userInfo}
              field={field}
            />
          );
        }
        case ContractFieldDto.type.DATE: {
          return (
            <DateField fieldData={item} fieldVisibleId={key} fields={fields} />
          );
        }
        default:
          return (
            <SimpleField fieldData={item} fieldVisibleId={key} field={field} />
          );
      }
    });
  };

  const renderContractMoved = () => {
    const sourceTeamName = teams?.find(
      (team) => team.id === (changeLogData as ContractMovedData).sourceTeamId
    )?.name;
    const targetTeamName = teams?.find(
      (team) => team.id === (changeLogData as ContractMovedData).targetTeamId
    )?.name;
    const data = {
      from: { value: sourceTeamName },
      to: { value: targetTeamName },
    };
    return <EditEntityItem data={data} />;
  };

  const renderSignatureInitiated = () => {
    return Object.keys(changeLogData).map((key) => {
      const item = (changeLogData as SignatureRequestDTO)[
        key as keyof SignatureRequestDTO
      ];
      if (!item || !(item as string[] | RecipientDto[])?.length) return null;

      const fieldName = t(`common.signature.${key}`);

      switch (key) {
        case "recipients": {
          const recipientEmails = (item as RecipientDto[]).map(
            (recipient: RecipientDto) => recipient.email
          );
          const data = {
            to: { value: recipientEmails.join(", ") },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListBulletIcon />}
            />
          );
        }
        case "ccEmails": {
          const data = {
            to: { value: (item as string[]).join(", ") },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListBulletIcon />}
            />
          );
        }
        case "locale": {
          const data = {
            to: { value: t(`common.languages.${item as string}`) },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListBulletIcon />}
            />
          );
        }
        case "eID":
        case "withOrder": {
          const data = {
            to: { value: item ? "Enabled" : "Disabled" },
          };
          return (
            <EditEntityItem
              data={data}
              fieldName={fieldName}
              fieldIcon={<ListBulletIcon />}
            />
          );
        }
        default: {
          return null;
        }
      }
    });
  };

  const renderSignatureSent = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderSignatureCancelled = () => {
    const data = changeLogData as SignatureRequestCancelDTO;
    return (
      <FieldWithIconEntityItem name={data.cancelReason} variant="signature" />
    );
  };

  const renderSignatureDeclined = () => {
    const data = changeLogData as SignerDeclinedData;
    const declineInfo = `${data.name} (${data.email}): ${
      data?.declineReason ? data?.declineReason : ""
    } `;
    return <FieldWithIconEntityItem name={declineInfo} variant="signature" />;
  };

  const renderSignatureReminderSent = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderSignatureSigned = () => {
    const data = changeLogData as SignerData;
    const signerInfo = `${data.name} (${data.email})`;
    return <FieldWithIconEntityItem name={signerInfo} variant="signature" />;
  };

  const renderTaskAddedRemoved = () => {
    return (
      <FieldWithIconEntityItem
        name={(changeLogData as TaskData).title}
        variant="task"
      />
    );
  };

  const renderDocumentUploadedRemoved = () => {
    return (
      <FieldWithIconEntityItem
        name={(changeLogData as DocumentData).fileName}
      />
    );
  };

  const attachmentUploadedRemoved = () => {
    return (
      <FieldWithIconEntityItem name={(changeLogData as AttachmentData).title} />
    );
  };

  const entitiyByEventType = useMemo(() => {
    switch (eventType) {
      case ContractChangeLogDTO.event.CONTRACT_EDITED: {
        return renderContractEdited();
      }
      case ContractChangeLogDTO.event.CONTRACT_INITIATED:
      case ContractChangeLogDTO.event.CONTRACT_CREATED: {
        return renderContractInitiated();
      }
      case ContractChangeLogDTO.event.DOCUMENT_UPLOADED:
      case ContractChangeLogDTO.event.DOCUMENT_REMOVED: {
        return renderDocumentUploadedRemoved();
      }
      case ContractChangeLogDTO.event.CONTRACT_MOVED: {
        return renderContractMoved();
      }
      case ContractChangeLogDTO.event.SIGNATURE_INITIATED: {
        return renderSignatureInitiated();
      }
      case ContractChangeLogDTO.event.SIGNATURE_SENT: {
        return renderSignatureSent();
      }
      case ContractChangeLogDTO.event.SIGNATURE_CANCELLED: {
        return renderSignatureCancelled();
      }
      case ContractChangeLogDTO.event.SIGNATURE_DECLINED: {
        return renderSignatureDeclined();
      }
      case ContractChangeLogDTO.event.SIGNATURE_REMINDER_SENT: {
        return renderSignatureReminderSent();
      }
      case ContractChangeLogDTO.event.SIGNATURE_SIGNED: {
        return renderSignatureSigned();
      }
      case ContractChangeLogDTO.event.CONTRACT_TASK_ADDED:
      case ContractChangeLogDTO.event.CONTRACT_TASK_REMOVED: {
        return renderTaskAddedRemoved();
      }
      case ContractChangeLogDTO.event.ATTACHMENT_ADDED:
      case ContractChangeLogDTO.event.ATTACHMENT_REMOVED:
      case ContractChangeLogDTO.event.MAIN_CONTRACT_CHANGED: {
        return attachmentUploadedRemoved();
      }
      default:
        return "";
    }
  }, [
    ContractChangeLogDTO,
    attachmentUploadedRemoved,
    renderContractEdited,
    renderContractInitiated,
    renderContractMoved,
    renderDocumentUploadedRemoved,
    renderSignatureCancelled,
    renderSignatureDeclined,
    renderSignatureInitiated,
    renderSignatureReminderSent,
    renderSignatureSent,
    renderSignatureSigned,
    renderTaskAddedRemoved,
  ]);

  const isLoading = !userInfo;

  if (isLoading) return <Loader />;

  const date = dayjs(changeLog.createdAt).format(
    `${userInfo?.dateFormat} HH:mm`
  );

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <Date>{date}</Date>
      </Header>
      <Body>{entitiyByEventType}</Body>
    </Wrapper>
  );
};

export default LogEntity;

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import CircularProgress from "@mui/material/CircularProgress";
import { LexicalEditor, EditorState } from "lexical";
import { motion, MotionStyle } from "framer-motion";
import isEqual from "lodash/isEqual";
import { ContractDTOV1, DocumentDTO, ContractTemplateService } from "openapi";
import { useContractDetails, viewModeType } from "pages/Contract/context";
import { getTemplateObj } from "pages/Contract/helpers";
import TextEditor from "new-components/TextEditor/TextEditor";
import PDFComponent from "../RightSide/PDFComponent/PDFComponent";
import ImportDocuments from "./ImportDocument";
import { motionProps } from "../RightSide/RightSide";
import { AcceptedFileType } from "shared/enums/document.enum";
import { CardWrapper, Container } from "./styles";
import DocumentHeader from "./components/DocumentHeader/DocumentHeader";
import { useTeam } from "contexts/team/hooks";
import { useContractQuery } from "shared/api/contracts";
import { useDownloadDocumentQuery } from "shared/api/documents";
import { useFetchDocumentQuery } from "shared/api/documents";
import { theme } from "theme";
import { enqueueSnackbar } from "notistack";
import { extractFileName } from "constants/utils";

export const motionStyles = {
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  width: "100%",
} as MotionStyle;

type DocumentProps = {
  onBackClick?: () => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
  selectedDocument?: DocumentDTO;
};

const MotionWrapper: React.FC<{
  children: React.ReactNode;
  padding?: string;
}> = ({ children, padding = "0" }) => (
  <motion.div
    {...motionProps}
    style={{ ...motionStyles, padding: padding || theme.spacing.md }}
  >
    {children}
  </motion.div>
);

const Document = ({
  onBackClick,
  onDeleteClick,
  onDownloadClick,
  selectedDocument,
}: DocumentProps) => {
  const {
    viewMode,
    setViewMode,
    isLoadingCreatePDFDocument,
    editorRef,
    localEditorState,
    setLocalEditorState,
    hasCreatedPDF,
  } = useContractDetails();
  const { mode: routeParamMode, id: contractId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeAsSearchParam = searchParams.get("mode");
  const mode =
    routeParamMode === viewModeType.EDIT ||
    modeAsSearchParam === viewModeType.EDIT
      ? viewModeType.EDIT
      : viewModeType.VIEW;
  const { selectedTeamId, organizationId } = useTeam();
  const { data: contract = {} as ContractDTOV1 } = useContractQuery(
    selectedTeamId,
    contractId
  );

  const { data: documentFile, isLoading } = useDownloadDocumentQuery(
    organizationId,
    contract.teamId,
    contractId,
    selectedDocument?.id
  );

  const { data: document, isLoading: IsDocumentLoading } =
    useFetchDocumentQuery(
      organizationId,
      contract.teamId,
      contractId,
      selectedDocument?.id
    );
  const [initialEditorState, setInitialEditorState] =
    useState<EditorState | null>(null);
  const [temporaryPDFFile, setTemporaryPDFFile] = useState<Blob | undefined>(
    undefined
  );
  const [isLoadingTemporaryPDFView, setIsLoadingTemporaryPDFView] =
    useState(false);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [isLexical, setIsLexical] = useState<boolean>(
    document?.mimetype === AcceptedFileType.LEXICAL
  );

  useEffect(() => {
    setViewMode(
      mode === viewModeType.EDIT ? viewModeType.EDIT : viewModeType.VIEW
    );
  }, [mode]);

  const generateTemporaryPDFFile = useCallback(async () => {
    if (
      !document ||
      viewMode === viewModeType.EDIT ||
      document.mimetype !== AcceptedFileType.LEXICAL
    ) {
      return;
    }
    if (!editorRef.current) return;

    const editorState = editorRef.current.getEditorState();

    if (!editorState) return;

    try {
      setIsLoadingTemporaryPDFView(true);

      const pdf = (await ContractTemplateService.renderContractTemplate(
        organizationId,
        {
          editorState: JSON.stringify(editorState),
        }
      )) as Blob;

      if (!pdf) return;

      setTemporaryPDFFile(pdf);
    } catch (error) {
      enqueueSnackbar(
        "pages.contractDetails.notifications.error_generate_temporary_pdf"
      );
      Sentry.captureException(error);
    } finally {
      setIsLoadingTemporaryPDFView(false);
    }
  }, [editorRef, document, viewMode]);

  useEffect(() => {
    if (document) {
      const lexical = document?.mimetype === AcceptedFileType.LEXICAL;
      setIsLexical(lexical);
      setShowEditor(lexical && viewMode === viewModeType.EDIT);
    }
  }, [document, viewMode]);

  useEffect(() => {
    if (!selectedDocument) {
      return;
    }

    if (selectedDocument?.mimetype === AcceptedFileType.LEXICAL) {
      if (
        !searchParams.has("mode") ||
        searchParams.get("mode") !== viewModeType.EDIT
      ) {
        setSearchParams({ mode: viewModeType.EDIT });
      }
    } else {
      if (
        searchParams.has("mode") &&
        searchParams.get("mode") === viewModeType.EDIT
      ) {
        setSearchParams((prevParams) => {
          prevParams.delete("mode");
          return prevParams;
        });
      }
    }
  }, [selectedDocument]);

  const handleEditorChange = useCallback(
    (editorState: EditorState) => {
      const currentStateJSON = editorState.toJSON();
      const initialStateJSON = initialEditorState?.toJSON();

      if (!isEqual(currentStateJSON, initialStateJSON)) {
        setLocalEditorState(editorState);
      }
    },
    [initialEditorState, localEditorState]
  );

  useEffect(() => {
    editorRef.current?.setEditable(mode === viewModeType.EDIT);
  }, [viewMode]);

  useEffect(() => {
    if (hasCreatedPDF) {
      return;
    }
    void generateTemporaryPDFFile();
  }, [isLexical, viewMode, document, hasCreatedPDF]);

  const initializeEditorState = async (editor: LexicalEditor) => {
    try {
      if (!documentFile) return;

      const templateData = await getTemplateObj(documentFile);

      if (!templateData) return;

      const draftContent = templateData.content;

      if (!draftContent) return;

      const parsedState = editor.parseEditorState(draftContent);

      if (localEditorState) {
        editor.setEditorState(localEditorState);
      } else {
        editor.setEditorState(parsedState);
        setInitialEditorState(parsedState);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (isLoadingCreatePDFDocument) {
    return (
      <CardWrapper>
        <CircularProgress />
      </CardWrapper>
    );
  }

  if (!selectedDocument) {
    return (
      <MotionWrapper padding={theme.spacing.xl}>
        <ImportDocuments contractData={contract} />
      </MotionWrapper>
    );
  }

  if (isLoading || IsDocumentLoading || isLoadingTemporaryPDFView) {
    return (
      <CardWrapper>
        <CircularProgress />
      </CardWrapper>
    );
  }

  return (
    <>
      <Container>
        <MotionWrapper>
          <DocumentHeader
            contractFile={documentFile}
            headerTitle={extractFileName(document?.filename || "")}
            onBackClick={onBackClick}
            onDeleteClick={onDeleteClick}
            onDownloadClick={onDownloadClick}
          />
          {showEditor ? (
            <TextEditor
              editorRef={editorRef}
              onChange={handleEditorChange}
              initialState={initializeEditorState}
            />
          ) : (
            <PDFComponent
              selectedDocument={selectedDocument}
              contractData={contract}
              data-testid="pdf-component"
              temporaryPdf={isLexical ? temporaryPDFFile : undefined}
            />
          )}
        </MotionWrapper>
      </Container>
    </>
  );
};

export default Document;

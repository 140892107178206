import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useState } from "react";
import { FILL_PLACEHOLDER_COMMAND } from "../../plugins/TemplatePlugin";
import { getFieldIcon } from "pages/Contract/components/ContractChangeLog/components/LogEntity/LogEntity";
import { ContractFieldDTOV1 } from "openapi";
import { PlaceholderTextNode } from "../../nodes/PlaceholderTextNode";
import { Input, IconWrapper, TemplateComponentWrapper, Name } from "./styles";

export type TemplateComponentProps = {
  draggable?: boolean;
  slug: string;
  fieldKey: string;
  label: string;
  type: ContractFieldDTOV1.type;
  getLocalizedLabelParts: (
    slug: string,
    fieldKey: string
  ) => {
    slug: string | null;
    fieldKey: string | null;
  };
};

const TemplateComponent = (props: TemplateComponentProps) => {
  // move all lexical functionality in upper component / edit functionality as well
  const [editor] = useLexicalComposerContext();

  const [isDragging, setIsDragging] = useState(false);

  const [editable, setEditable] = useState(false);

  const fieldIcon = getFieldIcon(props?.type);

  const localizedLabelParts = props.getLocalizedLabelParts(
    props.slug,
    props.fieldKey
  );

  return (
    <TemplateComponentWrapper
      draggable={props.draggable}
      onDragStart={(e) => {
        e.dataTransfer.setData("text/plain", JSON.stringify(props));
        e.dataTransfer.effectAllowed = "copy";
      }}
      contentEditable={false}
      onDoubleClick={() => {
        setEditable(true);
      }}
      onDrag={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      isDragging={isDragging}
    >
      <IconWrapper>{fieldIcon}</IconWrapper>
      <Name>
        {PlaceholderTextNode.getDefaultText(
          localizedLabelParts.slug || props.slug,
          localizedLabelParts.fieldKey || props.fieldKey
        )}
      </Name>
      {editable && (
        <Input
          type="text"
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              editor.dispatchCommand(FILL_PLACEHOLDER_COMMAND, {
                origin: "internal",
                slug: props.slug,
                fieldKey: props.fieldKey,
                value: e.currentTarget.value,
              });
              setEditable(false);
            }
          }}
        />
      )}
    </TemplateComponentWrapper>
  );
};
export default TemplateComponent;
